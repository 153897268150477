import React from "react";
import Layout from "../../components/layout";
import HeaderDivideText from "../../components/headerDivideText";
import IndexCard from "../../components/indexCard";
import {StaticImage} from "gatsby-plugin-image";
import AlisonProfile from "../../components/alisonProfile";
import TeamMembers from "../../components/teamMembers";

export default () => {
  return (
    <Layout
      titleText="Our coaches drive real change"
      titleText2="and improvement."
      slogan="We bring high levels of real world and coaching experience to support and help our clients
        move forward. "
      includeRandomTestimonial={false}
    >
      <AlisonProfile />

      <HeaderDivideText
        style={{marginTop: "115px"}}
        header="All our coaches have
        broad levels of experience."
        column="col-md-6"
      />

      <div className="flex flex-wrap gap-3 justify-content-center align-items-center mt-5">
        <IndexCard
          title="Real world experience"
          text="Coaches have several years operational industry experience or as a practicing psychologist."
        >
          <StaticImage
            src="../images/icons/old/RealWorldExperience.png"
            alt="image"
            quality={100}
          />
        </IndexCard>

        <IndexCard
          title="Coaching expertise"
          text="Coaches have 250+ hours of paid professional coaching experience and strong feedback from clients."
        >
          <StaticImage
            src="../images/icons/old/CoachingExpertise.png"
            alt="image"
            quality={100}
          />
        </IndexCard>

        <IndexCard
          title="Accreditation"
          text="Coaches are accredited with one of the international coaching associations and undertake regular professional development."
        >
          <StaticImage
            src="../images/icons/old/Accreditation.png"
            alt="image"
            quality={100}
          />
        </IndexCard>

        <IndexCard
          title="Higher Education"
          text="Preference for coaches with an advanced degree from an accredited university and/or a professional qualification."
        >
          <StaticImage
            src="../images/icons/old/ProffessionalDevelopment.png"
            alt="image"
            quality={100}
          />
        </IndexCard>

        <IndexCard
          title="References"
          text="Coaches must pass a rigorous vetting process."
        >
          <StaticImage
            src="../images/icons/old/References.png"
            alt="image"
            quality={100}
          />
        </IndexCard>

        <IndexCard
          title="Professional Development"
          text="Coaches must complete continuing professional development."
        >
          <StaticImage
            src="../images/icons/old/HigherEducation.png"
            alt="image"
            quality={100}
          />
        </IndexCard>
      </div>

      <TeamMembers />
    </Layout>
  );
};
